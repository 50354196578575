import {getNetworkAPIAssetResolveURL, NetworkAPIThumbnailURL} from '@common/network/network.utils';
import {isCypressEnv} from '@common/utils/utils-e2e';
import moment from 'moment';

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

const mainSiteLink = link => {
  return process.env.VUE_APP_WEB_SITE_BASE + (link && link.length > 0 ? link : '');
};

const webSiteLink = link => {
  return process.env.VUE_APP_WEB_SITE_BASE + (link && link.length > 0 ? link : '');
};

const s3ThumbnailLink = (link, width) => {
  return NetworkAPIThumbnailURL + '?url=' + encodeURIComponent(link) + '&width=' + width;
};

const sourceImageFastThumbnailLink = (token, width) => {
  return `${process.env.VUE_APP_THUMBNAIL_BASE}/token/${token}/thumbnail.webp?width=${width}`;
};

const getLazyLoadThumbnailLink = ({link, quality = 10, maxWidth = null}) => {
  const maxWidthParam = maxWidth ? `&max_width=${maxWidth.toString()}` : '';
  return `${NetworkAPIThumbnailURL}?url=${encodeURIComponent(link)}&quality=${quality}${maxWidthParam}`;
};

const getAssetLink = assetId => {
  return getNetworkAPIAssetResolveURL(assetId);
};

const getAssetLazyLoadThumbnailLink = ({assetId, quality = 10, maxWidth = null}) => {
  const maxWidthParam = maxWidth ? `&max_width=${maxWidth.toString()}` : '';
  return `${getNetworkAPIAssetResolveURL(assetId)}?quality=${quality}${maxWidthParam}`;
};

const getTreeImagePreviewLink = ({treeId}) => {
  return `${process.env.VUE_APP_API_BASE}api/people/tree/image-preview/${treeId}/tree.png`;
};

const getImageSrc = image => {
  if (image.startsWith('http')) {
    return image;
  }
  if (image.startsWith('~@/')) {
    let imgSrc = `${image.replace('~@/assets/', '')}`;
    return getAsset('/assets/' + imgSrc);
  }
  return getAssetLink(image);
};

const getImageLazyLoadPlaceholderSrc = (image, maxWidth = 1024) => {
  if (isCypressEnv()) {
    return getImageSrc(image);
  }
  if (image.startsWith('~@/')) {
    let imageSrc = image.replace('~@/assets/', '/assets/');
    image = getAsset(imageSrc);
    return getLazyLoadThumbnailLink({link: image, maxWidth});
  }
  if (image.startsWith('http')) {
    return getLazyLoadThumbnailLink({link: image, maxWidth});
  }
  return getAssetLazyLoadThumbnailLink({assetId: image, maxWidth});
};

const getGalleryImageData = (id, src, description, additionalData = {}, initInfoBarHidden = false) => {
  return {_id: id, src, description, initInfoBarHidden, ...additionalData};
};

const isChineseText = (contextString, threshold = 1) => {
  if (!contextString) {
    return false;
  }
  const totalLength = contextString.length;
  const chineseLength = (contextString.match(/[\u3400-\u9FBF]/gi) || []).length;
  return (100 / totalLength) * chineseLength >= threshold;
};

const isEmailValid = email => {
  return /^([A-Za-z0-9_\-\+\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(email);
};

const getAsset = path => `${process.env.VUE_APP_CND_DOMAIN}/src${path}`;

const toQueryString = paramsObject => {
  return Object.keys(paramsObject)
    .filter(key => paramsObject[key])
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
    .join('&');
};

function slugify(text) {
  // If text starts with {{ and end with }} - do nothing
  if (text && text.startsWith('{{') && text.endsWith('}}')) {
    return text;
  }
  return text
    ? text
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
    : '';
}

const responseToBase64String = response => {
  const b64Response = Buffer.from(response, 'binary').toString('base64');
  return 'data:image/jpeg;base64,' + b64Response;
};

const escapeHtml = unsafe => {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;');
};

const getHighlightString = str => {
  return `<strong>${str}</strong>`;
};

const getLifetimeYears = (birthDate, deathDate, isDeceased) => {
  const birthYear = birthDate && birthDate.year;
  const deathYear = deathDate && deathDate.year;
  if (isDeceased === false) {
    return birthYear ? `${birthYear} - Living` : 'Living';
  }
  if (birthYear || deathYear) {
    return `${birthYear || '?'} - ${deathYear || '?'}`;
  }
  return '';
};

const getApproximateYear = (year, approximationYears) => {
  approximationYears = approximationYears || 0;
  return year && approximationYears ? `${year - approximationYears}~${year + approximationYears}` : year;
};

const dayMonthYearToText = (day, month, year, approximation_years, highlight = false) => {
  if (!year && !month && !day) {
    return null;
  }
  const date = year && month ? new Date(year, month - 1, day || 1) : null;
  if (date && date.toString() === 'Invalid Date') {
    const yearDisplay = highlight ? getHighlightString(year) : year;
    return [day, month, yearDisplay].join(' ').trim();
  }

  month = month
    ? moment()
        .month(month - 1)
        .format('MMM')
    : '';
  year = getApproximateYear(year, approximation_years);
  year = highlight ? getHighlightString(year) : year;

  return [day, month, year].join(' ').trim();
};

const formatApproximateDate = ({day, month, year, approximation_years, text_value, cn_date}, highlight = false) => {
  const date = dayMonthYearToText(day, month, year, approximation_years, highlight) || text_value;
  return date && cn_date ? `${date} (${cn_date})` : date || cn_date;
};
const getFormattedFactText = fact => {
  const {value, start_date, end_date, place_display_text, cemetery, info} = fact;
  return [
    value,
    start_date ? formatApproximateDate(start_date) : '',
    end_date ? formatApproximateDate(end_date) : '',
    place_display_text,
    cemetery ? cemetery.name : '',
    ...info.map(infoItem => `${infoItem.label}: ${infoItem.value}`),
  ]
    .filter(text => !!text)
    .join('\n');
};

const getDateLocation = (date, location) => {
  if (!date && !location) {
    return '';
  }
  const formattedDate = date ? formatApproximateDate(date) : date;
  if (formattedDate && location) {
    return `${formattedDate} in ${location}`;
  }
  return formattedDate || location || '';
};

const getDateLocationHighlighted = (date, location, highlightDate, highlightLocation) => {
  if (!date && !location) {
    return '';
  }
  const formattedDate = date ? formatApproximateDate(date, highlightDate) : date;
  const locationDisplay = highlightLocation && location ? getHighlightString(location) : location;
  if (formattedDate && locationDisplay) {
    return `${formattedDate} in ${locationDisplay}`;
  }
  return formattedDate || locationDisplay || '';
};

const getFormattedResidenceText = (residence, highlight = false) => {
  let years =
    residence.from_year || residence.to_year ? ` (${residence.from_year || '?'}-${residence.to_year || '?'})` : '';
  const canHighlight = highlight && residence && residence.highlight;
  const shouldHighlightYear = canHighlight && residence.highlight.includes('years');
  const shouldHighlightPlace =
    canHighlight && (residence.highlight.includes('place_ids') || residence.highlight.includes('display_text'));
  years = shouldHighlightYear ? getHighlightString(years) : years;
  const displayText = shouldHighlightPlace ? getHighlightString(residence.display_text) : residence.display_text;
  return `${displayText}${years}`;
};

const getDatesDiffInDays = (start, end) => {
  const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
  const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

const getRandomString = (charCount = 7) => {
  return Math.random()
    .toString(36)
    .substring(2, 2 + charCount);
};

const isElementVisible = el => {
  const scroll = window.scrollY || window.pageYOffset;
  const boundsTop = el.getBoundingClientRect().top + scroll;

  const viewport = {top: scroll, bottom: scroll + window.innerHeight};
  const bounds = {top: boundsTop, bottom: boundsTop + el.clientHeight};

  return (
    (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
    (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
  );
};

const scrollPastElement = el => {
  const scroll = window.scrollY || window.pageYOffset;
  const boundsTop = el.getBoundingClientRect().top + scroll;

  const viewport = {top: scroll, bottom: scroll + window.innerHeight};
  const bounds = {top: boundsTop, bottom: boundsTop + el.clientHeight};

  return viewport.top >= bounds.bottom;
};

const splitByFirstSpace = str => {
  if (!str) return [];
  const i = str.indexOf(' ');
  if (i > 0) {
    return [str.substring(0, i), str.substring(i + 1)];
  } else return [str];
};

function truncateText(text, length) {
  if (text.length <= length) {
    return text;
  }
  return text.substr(0, length) + '\u2026';
}

export {
  escapeHtml,
  getApproximateYear,
  getLifetimeYears,
  formatApproximateDate,
  getDateLocation,
  getDateLocationHighlighted,
  getHighlightString,
  getFormattedResidenceText,
  getFormattedFactText,
  getAsset,
  getAssetLazyLoadThumbnailLink,
  getAssetLink,
  getTreeImagePreviewLink,
  getDatesDiffInDays,
  getGalleryImageData,
  getImageLazyLoadPlaceholderSrc,
  getImageSrc,
  getLazyLoadThumbnailLink,
  getRandomString,
  isChineseText,
  isElementVisible,
  isEmailValid,
  mainSiteLink,
  responseToBase64String,
  s3ThumbnailLink,
  scrollPastElement,
  slugify,
  sourceImageFastThumbnailLink,
  splitByFirstSpace,
  toQueryString,
  webSiteLink,
  truncateText,
};
