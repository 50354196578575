import {NetworkAPIVersion, NetworkParamType} from './network.utils';

const UPDATE_PERSON_PARAMS = {
  birth_date: [NetworkParamType.object],
  birth_location: [NetworkParamType.object],
  death_date: [NetworkParamType.object],
  death_location: [NetworkParamType.object],
  first_names: [NetworkParamType.array],
  gender: [NetworkParamType.string],
  is_deceased: [NetworkParamType.boolean],
  surnames: [NetworkParamType.object],
  notes: [NetworkParamType.string],
  residence_location: [NetworkParamType.array],
  generation_number: [NetworkParamType.number],
  relatives_json: [NetworkParamType.array],
  info: [NetworkParamType.array],
};

export default {
  details: {
    url: 'person/{id}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    authorization: true,
  },
  update: {
    url: 'person/{id}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'patch',
    authorization: true,
    params: UPDATE_PERSON_PARAMS,
  },
  updateFacts: {
    url: 'person/{id}/facts/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      facts: [NetworkParamType.array],
    },
  },
  importRelatives: {
    url: 'person/{id}/import-relatives/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      main_person_id: [NetworkParamType.number],
      new_relatives: [NetworkParamType.object],
      from_hint: [NetworkParamType.boolean],
    },
  },
  acceptData: {
    url: 'person/{id}/accept-data/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      data: [NetworkParamType.object],
    },
  },
  relatives: {
    url: 'person/{id}/relatives/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    authorization: true,
  },
  options: {
    url: 'person/options/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    authorization: true,
  },
  delete: {
    url: 'person/{id}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'delete',
    authorization: true,
  },
  create: {
    url: 'person/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    authorization: true,
    params: {
      ...UPDATE_PERSON_PARAMS,
      family_tree_id: [NetworkParamType.string],
      hierarchy_level: [NetworkParamType.number],
    },
  },
  getPhotos: {
    url: 'person/{id}/photos/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  getFullNamesByIds: {
    url: 'person/full-names/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      ids: [NetworkParamType.string],
    },
  },
  getMySurnames: {
    url: 'person/my-surnames/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  uploadPhoto: {
    url: 'person/{id}/photos/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    multipart: true,
    params: {
      attachment: [NetworkParamType.file],
    },
  },
  setProfilePicture: {
    url: 'person/{id}/profile-picture/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    multipart: true,
    params: {
      attachment: [NetworkParamType.file],
      asset_id: [NetworkParamType.string],
    },
  },
  comparePersons: {
    url: 'persons-compare/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      person_id: [NetworkParamType.number],
      match_person_id: [NetworkParamType.number],
    },
  },
  getExtraData: {
    url: 'person/{id}/extra-data/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    autoCancel: true,
  },
};
